/deep/ .breadcrumb {
  border-bottom: 0 !important;
  flex-shrink: 0;
}

/deep/ .compares_header {
  flex-shrink: 0;
}

.compared {
  height: 100vh;
  display: flex;
  flex-direction: column;
  position: relative;
}

.compare_list {
  padding: 0.5rem 0.4rem 0.5rem;
  display: flex;
  flex: 1;
}

.compare_list .outline {
  border-right: 0.01rem solid #e5e5e5;
}

.compare_list .outline:last-of-type {
  border-right: 0;
}

/deep/ .ant-spin-blur::after {
  background-color: #000;
}

.goback {
  position: absolute;
  left: 0.5rem;
  top: 0.2rem;
}