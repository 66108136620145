.compares_header {
  background-color: #f5f5f5;
  padding: 0.1rem 0;
}

.compares_header .auto-1440 {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.compares_header .auto-1440 .left {
  display: flex;
  align-items: center;
  flex: 1;
}

.compares_header .auto-1440 .left .list {
  display: flex;
}

.compares_header .title {
  font-size: 0.24rem;
  color: #000f23;
  font-weight: bold;
  line-height: 1.167;
  margin-right: 0.3rem;
  flex-shrink: 0;
}

.compares_header .list {
  flex: 1;
}

.compares_header .list .item {
  display: flex;
  padding: 0.1rem;
  border-radius: 0.1rem;
  background-color: #fff;
  width: calc(25% - 0.1rem);
  margin-right: 0.1rem;
}

.compares_header .list .item .label {
  font-size: 0.14rem;
  width: 0.3rem;
  text-align: center;
  padding: 0.15rem 0.05rem;
  line-height: 1;
  margin-right: 0.2rem;
  flex-shrink: 0;
  font-weight: bold;
  color: #000;
  background-color: #00ffc0;
  border-radius: 0.1rem 0 0.1rem 0;
}

.compares_header .list .item h2 {
  font-size: 0.14rem;
  color: #555555;
  line-height: 1.714;
}

.compares_header .list .item .delete {
  align-self: center;
  flex-shrink: 0;
  margin-left: 0.1rem;
  transition: 0.3s;
  font-size: 0.16rem;
  cursor: pointer;
}

.compares_header .list .item .delete .close {
  margin: 0.05rem 0;
}

.compares_header .list .item .delete .svg-icon {
  outline: none;
}

.compares_header .list .item .delete:hover {
  color: #f6a75c;
}

.compares_header .right {
  display: flex;
  align-items: center;
}

.compares_header .right .add {
  height: 1.2rem;
  display: flex;
  width: 1rem;
  flex-direction: column;
  background-color: #fff;
  align-items: center;
  justify-content: center;
  border-radius: 0.1rem;
  cursor: pointer;
  transition: 0.3s;
  color: #bbbbbb;
}

.compares_header .right .add .svg-icon {
  font-size: 0.12rem;
  margin-bottom: 0.1rem;
}

.compares_header .right .add span {
  font-size: 0.14rem;
  line-height: 1;
}

.compares_header .right .add:hover {
  color: #00ffc0;
}