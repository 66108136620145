.demo-infinite-container::-webkit-scrollbar {
  width: 0.06rem;
  border-radius: 0.03rem;
  background-color: #e5e5e5;
}

.demo-infinite-container::-webkit-scrollbar-thumb {
  width: 100%;
  border-radius: 0.03rem;
  background-color: #f6a75c;
}

.item {
  display: flex;
  padding: 0.2rem 0 0.15rem;
  align-items: center;
  justify-content: space-between;
  flex: 1;
}

.item .txt {
  flex: 1;
}

.item .txt h2 {
  font-size: 0.18rem;
  font-weight: bold;
  line-height: 1;
  margin-bottom: 0.1rem;
}

.item .txt .des {
  line-height: 1.5;
  font-size: 0.14rem;
}

.item .handle {
  flex-shrink: 0;
  margin-left: 0.2rem;
}

.item .handle .ant-btn {
  background-color: #79f9d9;
  border-color: #79f9d9;
  padding: 0 0.15rem;
  margin-left: 0.05rem;
}

.item .handle .ant-btn:focus,
.item .handle .ant-btn:hover {
  background-color: #00ffc0;
  border-color: #00ffc0;
  color: #000;
  box-shadow: 0 0 0.1rem 0 #00ffc0;
}

:deep(.ant-list) {
  overflow: auto;
  height: 5rem;
  padding-right: 0.2rem;
}

:deep(.ant-list)::-webkit-scrollbar {
  width: 0.06rem;
  border-radius: 0.03rem;
  background-color: #e5e5e5;
}

:deep(.ant-list)::-webkit-scrollbar-thumb {
  width: 100%;
  border-radius: 0.03rem;
  background-color: #f6a75c;
}

.page {
  text-align: center;
}

.page :deep(.ant-pagination-item),
.page :deep(.ant-pagination-prev),
.page :deep(.ant-pagination-next) {
  width: 0.4rem;
  height: 0.4rem !important;
  line-height: 0.4rem !important;
}

:deep(.ant-modal-body) {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}