.outline {
  padding: 0 0.4rem 0.5rem 0.6rem;
  max-width: 15.5rem;
  margin: 0 auto;
  position: relative;
}

.outline .header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.outline .header .tag {
  line-height: 0.3rem;
  border-radius: 0 0.1rem 0 0.1rem;
  background-color: #00ffc0;
  padding: 0 0.1rem;
  color: #000;
  font-weight: bold;
  font-size: 0.14rem;
  margin-bottom: 0.15rem;
}

.outline .header .close {
  width: 0.3rem;
  height: 0.3rem;
  text-align: right;
  cursor: pointer;
  color: #bbbbbb;
  transition: .3s;
}

.outline .header .close .svg-icon {
  font-size: 0.12rem;
}

.outline .header .close:hover {
  color: #f6a75c;
}

.outline .title {
  font-size: 0.2rem;
  line-height: 1.4;
  color: #000000;
  font-weight: bold;
  margin-bottom: 0.2rem;
}

.outline .model-tree {
  flex: 1;
  position: relative;
}

.outline3,
.outline4 {
  padding: 0 0.2rem 0.5rem 0.2rem;
}

.outline3 /deep/ .model_main,
.outline4 /deep/ .model_main {
  padding-right: 0.15rem;
}

.outline3 /deep/ .tree_title .svg-icon,
.outline4 /deep/ .tree_title .svg-icon {
  margin-left: 0.15rem;
}

.outline1 {
  width: 100%;
}

.outline2 {
  width: 50%;
}

.outline3 {
  width: 33.33%;
}

.outline4 {
  width: 25%;
}

/deep/ .ant-spin-nested-loading {
  height: 100%;
}

/deep/ .ant-spin-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
}

/deep/ .ant-spin-container .model_main {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

/deep/ .ant-spin-blur::after {
  background-color: #fff !important;
}